import { ComponentProps, FC } from 'react'
import { Blog } from 'api/cms'
import clsx from 'clsx'
import { Link } from 'ui/components/navigation'
import { formatFullDate } from 'ui/src/lib/formatters'

export interface ArticleCardProps extends ComponentProps<'div'> {
  readonly article: Blog
}

export const ArticleCard: FC<ArticleCardProps> = ({ article, className, ...props }) => {
  const coverImage = article.cardThumbnail || article.coverImage

  return (
    <Link href={`/articles/${article.slug}`} className={className}>
      <div className={clsx('hidden lg:block', className)} {...props}>
        <div className="text-deep-teal-500 text-sm font-normal text-black">{article.title}</div>
        <div className="text-sm font-normal text-cyan-900">{article.sponsor?.name}</div>
        <div className="text-deep-teal-300 text-sm font-normal">
          {formatFullDate(article.created_at)}
        </div>
      </div>

      <div className={clsx('flex gap-2 lg:hidden', className)} {...props}>
        <CoverImage className="flex-1 flex-shrink-0 self-start" src={coverImage?.url} />
        <div className="flex flex-1 flex-col justify-between gap-4">
          <div className="text-deep-teal-500 text-base font-semibold leading-tight">
            {article.title}
          </div>
        </div>
      </div>
    </Link>
  )
}

const CoverImage: FC<{ src?: string } & ComponentProps<'div'>> = ({ src, className, ...props }) => {
  const imageClassName = 'h-full w-full object-cover object-left-top aspect-[15/10]'

  return (
    <div className={clsx('overflow-hidden rounded', className)} {...props}>
      {src ? (
        <img data-testid="article-card-cover-image" className={clsx(imageClassName)} src={src} />
      ) : (
        <div
          data-testid="article-image-placeholder"
          className={clsx(imageClassName, 'bg-deep-teal-50')}
        />
      )}
    </div>
  )
}
