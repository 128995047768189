import { ComponentPropsWithoutRef, FC, useState } from 'react'
import { TopAnnouncementBanner, useTopAnnouncementBanner } from 'ui/components/content'
import { cn } from 'ui/lib'
import SidebarDesktop from './SidebarDesktop'
import SidebarMobile from './SidebarMobile'
import { Metadata } from '../../metadata'
import {
  SmsReceivingConsentModal,
  useIsSmsReceivingConsentModalAllowed,
} from '../SmsReceivingConsentModal'

export type SidebarLayoutProps = ComponentPropsWithoutRef<'div'> & {
  title?: string
  hideToolbar?: boolean
  Toolbar?: FC
  banner?: JSX.Element
  hideAnnouncementBanner?: boolean
  hideNavigation?: boolean
  bgColorClass?: string
}

export default function SidebarLayout({
  hideAnnouncementBanner,
  hideNavigation,
  hideToolbar,
  title,
  banner,
  children,
  className,
  bgColorClass,
  Toolbar,
  ...props
}: SidebarLayoutProps) {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const onSidebarOpen = (open: boolean) => setSidebarOpen(open)

  const isSmsReceivingConsentModalAllowed = useIsSmsReceivingConsentModalAllowed()

  if (Toolbar) {
    console.warn('Overriding Toolbar is not supported by Sidebar Layout')
  }

  return (
    <>
      <Metadata title={title} />

      <SidebarDesktop onOpen={onSidebarOpen} hideNavigation={hideNavigation} />
      <SidebarMobile title={title} hideNavigation={hideNavigation} />

      <main
        className={cn([
          'flex h-fit min-h-full flex-col',
          'transition-all ease-in-out',
          sidebarOpen ? 'lg:pl-72' : 'lg:pl-16',
          bgColorClass,
        ])}
      >
        {!hideAnnouncementBanner && <DesktopTopAnnouncementBanner />}
        {banner}

        {isSmsReceivingConsentModalAllowed && <SmsReceivingConsentModal />}

        <div className={cn('flex-1', className)} {...props}>
          {children}
        </div>
      </main>
    </>
  )
}

function DesktopTopAnnouncementBanner() {
  const topAnnouncementBanner = useTopAnnouncementBanner()
  return <TopAnnouncementBanner {...topAnnouncementBanner} />
}
