import { FC, useEffect, useState } from 'react'
import { useAmityFile } from 'amity/src/lib'
import { useAxios } from 'api/lib'
import clsx from 'clsx'
import { Link } from 'ui/components/navigation'
import { formatFullDate } from 'ui/src/lib/formatters'
import { Card } from '../../Card'
import { GroupAvatar } from '../GroupAvatar'

export const GroupCard: FC<{ group: Amity.Community; showGroupAccessBadge?: boolean }> = ({
  group,
  showGroupAccessBadge = false,
}) => {
  const file = useAmityFile(group.avatarFileId!)
  const [viewUrl, setViewUrl] = useState<string>()

  const { data: investmentOpportunityId } = useAxios<number | null>(
    `/api/investment-opportunities/groupId/${group.communityId}`,
  )

  useEffect(() => {
    investmentOpportunityId
      ? setViewUrl(`/investments/${investmentOpportunityId}`)
      : setViewUrl(`/groups/${group.communityId}`)
  }, [group?.isPublic, investmentOpportunityId])

  return (
    <Link href={viewUrl}>
      <Card className="group flex flex-col hover:shadow-lg active:shadow-lg">
        <div className="flex w-full">
          <GroupAvatar
            className="mr-4 h-11 w-11 shrink-0 cursor-pointer text-base"
            fileUrl={file?.fileUrl}
            displayName={group.displayName}
          />

          <div className="flex w-full justify-between">
            <div className="flex flex-col items-start gap-1">
              <div className="text-deep-teal-500 block cursor-pointer text-sm font-bold group-hover:text-orange-600 group-active:text-orange-700">
                {group.displayName}
              </div>
              {showGroupAccessBadge && <AccessBadge isPublic={group.isPublic!} />}
            </div>
            {/* Desktop Metadata */}
            <GroupMetadata className="hidden sm:flex" group={group} />
          </div>
        </div>

        <div className="text-deep-teal-400 group-hover:text-deep-teal-500 mt-3 h-full text-xs sm:ml-[60px] sm:mt-2">
          {group.description}
        </div>
        {/* Mobile Metadata */}
        <GroupMetadata className="mt-1 flex sm:hidden" group={group} />
      </Card>
    </Link>
  )
}

const GroupMetadata: FC<{ className?: string; group: Amity.Community }> = ({
  className,
  group,
}) => {
  return (
    <div className={clsx('align-center flex justify-between gap-4', className)}>
      <div className="text-deep-teal-400 text-sm font-normal">
        {group.membersCount} {group.membersCount === 1 ? 'member' : 'members'}
      </div>
      <div className="text-deep-teal-300 text-sm font-normal italic">
        Updated at {formatFullDate(group.updatedAt!)}
      </div>
    </div>
  )
}

const AccessBadge: FC<{ isPublic: boolean }> = ({ isPublic }) => {
  return (
    <span
      className={clsx(
        'text-deep-teal-500 border-l-2 border-orange-500 font-medium',
        'inline-flex items-center pl-1 text-xs',
        'text-deep-gray-500',
      )}
    >
      {isPublic ? 'Public group' : 'Private group'}
    </span>
  )
}
