import { ComponentProps, FC, Fragment, ReactElement } from 'react'
import { Dialog as HeadlessDialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'

export interface DialogProps extends ComponentProps<'div'> {
  readonly showCloseButton?: boolean
  readonly showBackdrop?: boolean
  readonly onClose?: () => void
  readonly open: boolean
  readonly width?: DialogWidth
  readonly title?: string
  readonly actions?: ReactElement
  readonly containerClassName?: string
}

export type DialogWidth = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export const DIALOG_WIDTH: Readonly<Record<DialogWidth, string>> = {
  xs: 'max-w-xs',
  sm: 'max-w-md',
  md: 'max-w-xl',
  lg: 'max-w-3xl',
  xl: 'max-w-6xl',
}

export const Dialog: FC<DialogProps> = ({
  actions,
  children,
  open,
  onClose = () => {
    /* do nothing */
  },
  showBackdrop = true,
  showCloseButton = true,
  title,
  width = 'md',
  containerClassName,
}) => {
  const sizeCss = DIALOG_WIDTH[width]

  return (
    <Transition.Root show={open} as={Fragment}>
      <HeadlessDialog as="div" className="fixed inset-0 z-10" onClose={onClose}>
        <div className="flex h-screen min-h-screen items-center justify-center overflow-hidden p-2 text-center sm:p-8 sm:py-16">
          {showBackdrop && (
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <HeadlessDialog.Overlay
                data-testid="dialog-backdrop"
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              />
            </Transition.Child>
          )}

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {/* Below is the first visible element */}
            <div
              className={clsx(
                'flex transform flex-col overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:p-6 sm:align-middle',
                // Sizing
                'max-h-full w-full',
                sizeCss,
              )}
            >
              {showCloseButton && (
                <div className="absolute right-0 top-0 pr-5 pt-4">
                  <button
                    data-testid="dialog-close-button"
                    type="button"
                    className="text-deep-teal-400 rounded-md bg-white hover:text-orange-600 focus:outline-none"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              )}
              {title && (
                <HeadlessDialog.Title
                  as="h3"
                  className="text-deep-teal-500 h-6 shrink-0 truncate pr-6 text-lg font-medium leading-6"
                >
                  {title}
                </HeadlessDialog.Title>
              )}
              <div className={clsx('mt-2 flex-1 overflow-y-auto', containerClassName)}>
                {children}
              </div>
              {actions && (
                <div className="mt-5 flex flex-col gap-3 sm:mt-4 sm:flex-row-reverse">
                  {actions}
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </HeadlessDialog>
    </Transition.Root>
  )
}
