import { MutableRefObject, useEffect, useState } from 'react'

interface ScrollToBottomElement extends HTMLElement {
  scrollToPoint: (x: number, y: number, duration: number) => Promise<void>
}

export const useScrollToBottom = <T extends ScrollToBottomElement | HTMLElement | Window>(
  scrollContainerRef: MutableRefObject<T>,
): {
  setScrollToBottom: (scroll: boolean) => void
} => {
  const [scrollToBottom, setScrollToBottom] = useState(false)

  useEffect(() => {
    if (scrollToBottom) {
      const current = scrollContainerRef.current

      if (current && 'scrollToPoint' in current) {
        const scrollToBottomElement = current as ScrollToBottomElement
        // Note: For unknown reason scroll to bottom doesn't
        // work and has some distance from the bottom left.
        // Likely because of it's async nature.
        // scrollToBottomElement.scrollToBottom()
        // Note 2: Duration chosen to be 2000ms experimentally
        // to be close to the 'smooth' scroll behavior visually.
        scrollToBottomElement.scrollToPoint(0, scrollToBottomElement.scrollHeight, 2000)
      } else if (current instanceof HTMLElement) {
        current.scrollTo({
          top: current.scrollHeight,
          behavior: 'smooth',
        })
      } else if (current instanceof Window) {
        current.scrollTo({
          top: current.innerHeight,
          behavior: 'smooth',
        })
      }
      setScrollToBottom(false)
    }
  }, [scrollToBottom, scrollContainerRef])

  return {
    setScrollToBottom,
  }
}
